/************************************************
* mapElement
*************************************************/

    .mapElement {

        .maps-container {
          position: relative; 
          padding-bottom: 30%;
          height: 0; 
          overflow: hidden; 
          width: 100%;
          height: auto;

          .map {
            position: absolute; 
            top: 0; 
            left: 0; 
            width: 100%; 
            height: 100%; 
          }

          .marker {
            display: none;
          }

        }

    }


/************************************************
* RESPONSIVE
*************************************************/