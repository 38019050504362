/************************************************
* ARTICLES
*************************************************/
	
	.articles {
		padding: 6rem 0;
		background-color: $brandFourth;

		h2 {
			margin-top: 0;
		}
	}
	
	.article {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-top: 4rem;
		
		&:first-of-type {
			margin-top: 0;
		}

		.image {
			width: 18%;
			margin-right: 2rem;
		}

		.text {
			width: 65%;
		}

		h3 {
			margin-top: 0;
			color: $brandFirst;
		}

		a{
			&::after {
				@extend %icons;
				content: '\f105';
			}

			&:hover::after {
				text-decoration: none;
			}
		}

	}

	ul.f3-widget-paginator {
		li::before{
			content: '';
		}	
	}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1300px) {
		.article .image {
			width: 20%;
		}
	}

	@media (max-width: 1200px) {
		.articles {
			padding: 4rem 0;
		}
	}

	@media (max-width: 1050px) {
		.article .image {
			width: 23%;
		}
	}

	@media (max-width: 800px) {
		.article .image {
			width: 27%;
		}
	}

	@media (max-width: 700px) {
		.articles {
			display: block;
		}

		.article .image,
		.article .text {
			width: 100%;
		}

		.article .text {
			margin-top: 1.5rem;
		}
	}

	@media (max-width: 600px) {
		.articles {
			padding: 2rem 0;
		}
	}