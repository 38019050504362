/************************************************
* IMAGEFULLWIDTH
*************************************************/

    .imageFullWidth {
        position: relative;

        .overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .5);
        }

        img {
            display: block;
        }

        .imageText {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15%;
            transform: translateY(-50%);
            z-index: 10;
            text-align: center;
            padding: 0 5%;
            font-size: 2rem;
            color: $brandSecond;
            display: block;
        }
    }

/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 900px) {
        .imageFullWidth .imageText {
            padding: 0 3%;
        }
    }

    @media (max-width: 800px) {
        .imageFullWidth .imageText {
            bottom: 10%;
        }
    }

    @media (max-width: 500px) {
        .imageFullWidth .imageText {
            bottom: 6%;
        }
    }
