/************************************************
* IMAGETEXTLIST
*************************************************/
    
    .imageTextList {
        background-color: $brandFourth;
        padding: 6rem 0 4rem 0;

        .wrap::after {
            display: none;
        }


        .wrap {
            > h1,
            > h2 {
                margin: 0 0 3rem 0;
            }
        }

        .entries {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .entry {
            width: 32%;
            margin: 0 2% 4rem 0;
            text-decoration: none;

            &:nth-of-type(3n) {
                margin-right: 0;
            }

            h2 {
                margin-bottom: 0;
            }

            h3 {
                color: $brandFirst;
                margin-bottom: .5rem;
            }

            li {
                word-wrap: break-word;
            }

            i {
                color: $brandFirst;
                margin-right: 1rem;
            }

            p {
                margin: 0;
            }

            .button {
                margin-left: 0;
            }
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 1200px) {
        .imageTextList {
            padding: 4rem 0;
        }
    }

    @media (max-width: 1000px) {
        .imageTextList {
            .entry {
                width: 48.5%;
                margin-right: 3%;

                &:nth-of-type(3n) {
                    margin-right: 3%;
                }

                &:nth-of-type(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .imageTextList {
            padding: 2rem 0;

            h2 {
                margin: 0 0 2rem 0;
            }
        }
    }

    @media (max-width: 550px) {
        .imageTextList {
            .entry {
                width: 100%;
                margin-right: 0;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }