/************************************************
* IMAGETEXTBOX
*************************************************/

    .imageTextBox {
        background-color: $brandFourth;
        padding: 6rem 0;

        h2 {
            margin-top: 0;
        }

        h3 {
            color: $brandFirst;
            margin: 0;
        }

        p {
            margin-top: 1.5rem;
        }

        .content {
            display: flex;
            justify-content: space-between;
            margin-top: 4rem;

            .image,
            .text {
                width: 47%;
            }
        }
    }

    .imageTextBox + .imageTextBox {
        padding-top: 0;
        .content{
            margin-top: 0;
        }
    }


/************************************************
* RESPONSIVE
*************************************************/
    
    @media (max-width: 1200px) {
        .imageTextBox {
            padding: 4rem 0;
        }

        .content {
            .image {
                width: 40%;
            }

            .text {
                width: 56%;
            }
        }
    }

    @media (max-width: 900px) {
        .imageTextBox {
            .content {
                display: block;
                margin-top: 5rem;

                .image {
                    width: 80%;
                }

                .text {
                    width: 80%;
                    margin-top: 2rem;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .imageTextBox {
            padding: 2rem 0;

            .content{
                .image,
                .text {
                    width: 100%;
                }
            }
        }
    }