/************************************************
* formLayout0
*************************************************/

.formLayout0 {
    padding: 0 0 6rem 0;
    background-color: $brandFourth;

    h1,
    h2 {
        margin-top: 0;
    }

    /* static text */
    h2 + div {
        margin-bottom: 4rem;
    }


    /* immer um ein form element (label + div) */
    .form-group {
        margin-bottom: 3rem;
        display: flex;
        justify-content: flex-start;

        > label {
            width: 30%;
        }

        > div,
        > p {
            width: 50%;
            &.checkbox {
                margin-left: 30%;
            }
        }

        .control-label + .checkbox {
            margin-left: 0;
        }
    }

    /* immer um ein static text element */
    .row .clearfix {
        padding-left: 30%;
        padding-right: 20%;
        margin-top: -2rem;
        margin-bottom: 3rem;

        p{
            margin-top: 0;
        }
    }



    /* Grid Row */

    .row {
        width: 100%;
        margin-bottom: 2rem;

        + .row h2 {
            margin-top: 3rem;
        }

    }

    /* Label */

    label {
        width: 100%;
        color: $fontColor;
        font-size: 1.8rem;
        display: block;
        padding: 1.3rem;
        padding-left: 0;
    }


    /* Fieldset */

    fieldset {
        margin: 0 0 2rem 0;
        padding: 0;
        border: 0;

        legend {
            font-size: 2rem;
            font-weight: 600;
            color: $brandFirst;
            margin-bottom: 2rem;
            padding: 0;
        }

        > .form-group,
        .clearfix {
            margin-bottom: 1rem;
        }
    }


    /* Input & Textarea */

    .input {
        width: 100%;

        input[type="text"],
        input[type="email"],
        textarea {
            border: none;
            padding: 1.3rem;
            width: 100%;
            font-family: 'Open Sans', sans-serif;
            font-size: 1.8rem;
            color: $fontColor;
            background-color: white;
            border-bottom: 2px solid white;
            outline: none;
            position: relative;
            &:focus {
                border-bottom: 2px solid $brandFirst;
            }
        }

        input[type="checkbox"] {
            margin-right: 1rem;
        }

        textarea {
            height: 20rem;
        }
    }

    /* file */

    .fileWrapper {
        position: relative;

        label {
            display: block;
            height: 100%;
            font-size: 1.8rem;
            padding: 1.3rem 1.3rem 1.3rem 0;
            position: absolute;
            width: 100%;
            padding-right: 71%;
            z-index: 20;

            &.selected + .button{
                &::after {
                    content: '\e805';
                }
            }
        }

        .button {
            margin: 0 0 0 30%;
            width: auto;
            background-color: white;
            color: $fontColor;
            border-radius: 0;

            &::after {
                content: '\f15b';
                color: $fontColor;
            }
        }

        html:lang(de) & .button {
            span {
                &::before{
                    content: 'Datei auswählen';
                }

                &.hasFile::before{
                    content: none;
                }
            }        
        }

        html:lang(en) & .button {
            span {
                &::before{
                    content: 'Select File';
                }

                &.hasFile::before{
                    content: none;
                }
            }          
        }

        .input{
            width: 0;
        }

        input {
            opacity: 0;
            visibility: hidden;
        }
    }

    /* Submit */
    
    .actions {
        padding-left: 30%;
        width: 100%;
        text-align: left;

        button {
            display: inline-block;
            color: white;
            font-size: 1.6rem;
            background-color: $brandThird;
            padding: 1.5rem 7rem 1.5rem 2rem;
            border-radius: 1rem;
            position: relative;
            transition: all .2s ease-in;
            cursor: pointer;

            @media (min-width: 1025px) {
                &:hover {
                    background-color: rgba($brandThird, .8);
                    text-decoration: none;
                }
            }

            &::before {
                content: '';
                width: 2px;
                height: 60%;
                background-color: $brandFirst;
                position: absolute;
                right: 5rem;
                top: 50%;
                transform: translateY(-50%);
            }

            &::after {
                @extend %icons;
                position: absolute;
                right: 1.5rem;
                top: 50%;
                transform: translateY(-50%);
                color: white;
                content: '\f178';
                transition: color .2s ease-in;
            }

        }
    }
}

/************************************************
 * Select 2 Stylings überschreiben
 ************************************************/

.select2-container--default{
    font-size: 1.6rem;
    max-width: 100%;

    .select2-selection--single{
        background-color: white;
        font-size: 1.8rem;
        border-radius: 0;
        border: none;
        padding: 1.2rem 1.3rem;
        padding-right: 8rem;
        height: auto;
        outline: none;

        .select2-selection__arrow {
            height: auto;
            bottom: 0;
            width: 5rem;
            top: 0;
            right: 0;
            border: none;
            background-color: white;
            border-left: 0;

            &::before {
                display: block;
                content: '';
                border-left: 2px solid $fontColor;
                position: absolute;
                top: 1rem;
                bottom: 1rem;
            }

            b {
                border: none;

                &::after{
                    @extend %icons;
                    content: '\f107';
                    position: absolute;
                    top: -.8rem;
                    left: -.8rem;
                    font-size: 2.5rem;
                    color: $brandFirst;
                }
            }
        }

        .select2-selection__rendered {
            color: $fontColor;
            font-size: 1.8rem;
            padding: 0;
            li:before{
                content: '';
            }
        }
    }


    // Container im offenen Zustand
    &.select2-container--open{
        .select2-selection--single .select2-selection__arrow b {
            border: none;
            &::after{
                content: '\f106';
            }
        }
    }

    .select2-results__option {
        padding: 1rem;
        color: $fontColor;
    }


    /* Hintergrund für aktive Auswahl*/
    .select2-results__option[aria-selected="true"]{
        background-color: white;
    }


    /* Hintergrund für gehoverte Auswahl*/
    .select2-results__option--highlighted[aria-selected]{
        background-color: #d3d3d3;
        color: $fontColor;
    }

    .select2-selection--multiple{
        font-size: 1.8rem;
        border-radius: 0;
        padding: .8rem .7rem .7rem .7rem;
        padding-right: 4rem;
        height: auto;
        line-height: 1.8rem;

        li {
            line-height: 1.5;
            font-size: 1.8rem;
        }

        .select2-selection__choice {
            margin-top: .2rem;
        }

        .select2-search--inline::before{
            content: '';
            input {
                font-size: 1.8rem;
            }
        }
    }
}


/* Hintergrund generell für die Optionen */
.select2-dropdown{
    background-color: white;
    border: none;
    border-radius: 0;
}

/************************************************
* RESPONSIVE
*************************************************/

@media(max-width: 1024px) {
    .formLayout0 {
        .form-group {
            > div {
                width: 70%;
            }
        }

        .row .clearfix {
            padding-right: 0;
        }
    }
}

@media(max-width: 700px) {
    .formLayout0 {
        .form-group {
            display: block;
            label,
            div {
                width: 100%;
                &.checkbox {
                    margin-left: 0;
                }
            }
        }

        .row .clearfix {
            padding-left: 0;
        }

        .actions {
            padding-left: 0;
        }

        .fileWrapper {
            label {
                padding-right: 0;
            }

            .button {
                margin: 6rem 0 0 0;
            }

            .input{
                margin: 0;
                padding: 0;
                height: 0;
            }

            input {
                height: 0;
                padding: 0;
            }
        }
    }
}