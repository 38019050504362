/************************************************
* CONTENTSLIDER
*************************************************/

    .contentSlider {
        background-color: $brandSecond;
        padding: 6rem 0 8rem 0;

        .wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .text {
            width: 48%;

            h2 {
                margin-top: 0;
                margin-bottom: 0;
            }

            p {
                margin: 1rem 0 1.5rem 0;
            }
        }

        .contentSliderWrapper {
            width: 48%;
            position: relative;
            padding: 0 3rem;
            display: block;
        }

        .slide {
            figure {
                margin: 0;
            }

            img {
                display: block;
                margin: 0 auto;
                width: auto;
            }

            figcaption {
                text-align: center;
                margin-top: 2rem;
                font-size: 1.8rem;
            }   
        }
    
        /* contentSlider right*/

        &.right {
            background-color: $brandFourth;

            .contentSliderWrapper {
                order: 1;
            }
        }
    }


    /* slider */

    .owl-theme .owl-controls {
        margin: 0;
    }

    .owl-theme .owl-nav {
        margin: 0;

        .owl-prev,
        .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: $brandThird;
            font-size: 2.7rem;
            margin: 0;
            background: transparent none;

            &:hover {
                background: transparent none;
                color: lighten($brandThird, 40%);
            }

            i::before{
                width: auto;
                margin: 0;
            }
        }

        .owl-prev {
            left: 0;
            padding-right: 2rem;
        }

        .owl-next {
            right: 0;
            padding-left: 2rem;
        }
    }

/************************************************
* RESPONSIVE
*************************************************/
    
    @media (max-width: 1200px) {
        .contentSlider {
            padding: 4rem 0 7rem 0;
        }
    }
    
    @media (max-width: 1024px) {
        .contentSlider {
            padding: 4rem 0 5rem 0;

            .slide img {
                width: 85%;
            }
        }
    }

    @media (max-width: 900px) {
        .contentSlider {
            padding: 4rem 0 7rem 0;

            .wrap {
                flex-direction: column-reverse;
            }

            &.right .contentSliderWrapper {
                order: 0;
            }

            .contentSliderWrapper,
            .text {
                width: 100%;
            }

            .contentSliderWrapper {
                margin-top: 2.5rem;
            }

            .text p {
                margin-bottom: 2rem;
            }

            .slide img {
                width: 70%;
            }
        }
    }

    @media (max-width: 600px) {
        .contentSlider {
            padding: 2rem 0 6rem 0;

            .slide img {
                width: 100%;
            }
        }
    }