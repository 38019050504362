/************************************************
* navFooter
*************************************************/

#navFooter{
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    a {
        color: $brandSecond;
        text-decoration: none;
        &:hover {
            color: darken($brandSecond, 20%);
        }
    }
}


/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 1024px) {
        #navFooter ul li a:hover {
            color: $brandSecond;
        }
    }

    @media (max-width: 900px) {
        #navFooter ul li {
            display: inline-block;
            margin-right: 2rem;
        }
    }

    @media (max-width: 500px) {
        #navFooter ul li {
            display: block;
            margin-top: 1rem;
        }
    }