/************************************************
* General CONTENT Stylings
*************************************************/

	footer {
		background-color: $brandThird;
		color: $brandSecond;
		padding: 3rem 0;

		.wrap {
			display: flex;
			justify-content: space-between;

			&::after {
				display: none;
			}
		}

		.left {
			width: 60%;
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			.col {
				width: 32%;
			}
		}

		.right {
			width: 25%;
			display: flex;
			justify-content: flex-end;
			.col {
				width: 48%;
				font-size: 1.3rem;
				line-height: 1.7;
				align-self: flex-end;
				&:last-of-type {
					text-align: right;
				}
			}
		}

		.headline {
			font-size: 1.5rem;
			margin-bottom: 2rem;
		}

		html:lang(de) & .social::before { 
		    content: 'Besuchen Sie uns auf';
		}

		html:lang(en) & .social::before { 
		    content: 'Visit us at';
		}

		html:lang(de) & .contact::before { 
		    content: 'Kontakt';
		}

		html:lang(en) & .contact::before { 
		    content: 'Contact';
		}

		html:lang(de) & .country::before { 
		    content: 'Deutschland';
		}

		html:lang(en) & .country::before { 
		    content: 'Germany';
		}
	}

	address {
		font-size: 1.3rem;
		line-height: 1.7;
		font-style: normal;
	}

	.tel,
	.email,
	.fax,
	a[href^="tel"] {
		text-decoration: none;
		color: $brandSecond;
		display: block;
	}

	.tel:hover,
	.fax:hover,
	.email:hover,
	a[href^="tel"]:hover {
		color: darken($brandSecond, 20%);
	}

	.tel:hover:before,
	.fax:hover:before,
	.email:hover:before,
	a[href^="tel"] {
		text-decoration: none;
	} 

	.tel::before {
		@extend %icons;
		content: '\e801';
		color: $brandFirst;
		margin-right: 1.5rem;
	}

	.fax::before {
		@extend %icons;
		content: '\e802';
		color: $brandFirst;
		margin-right: 1.5rem;
	}

	.email::before {
		@extend %icons;
		content: '\e800';
		color: $brandFirst;
		margin-right: 1.5rem;
	}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1024px) {
		footer{
			.left {
				width: 65%;
			}

			.right {
				width: 30%;
				.col {
					font-size: 1.5rem;
				}
			}

			.headline {
				font-size: 1.7rem;
			}
		}

		address {
			font-size: 1.5rem;
		}

		.tel:hover,
		.email:hover {
			color: $brandSecond;
		}
	}

	@media (max-width: 900px) {
		footer {

			.wrap {
				display: block;
			}

			.left {
				justify-content: space-between;
				width: 100%;
			}

			.right {
				width: 100%;
				justify-content: space-between;
				margin-top: 4rem;

				.col:first-of-type {
					width: 85%;
				}

				.col:last-of-type {
					width: 15%;
				}
			}
		}
	}

	@media (max-width: 600px) {
		footer {
			.left .col {
				width: 48%;
				margin-bottom: 3rem;
				&:last-of-type {
					margin-bottom: 0;
				}
			}

			.headline {
				margin-bottom: 1rem;
			}
		}
	}

	@media (max-width: 500px) {
		footer {
			.left .col {
				width: 100%;
			}

			.right {
				display: block;

				&:first-of-type {
					width: 100%;
				}

				.col:last-of-type {
					text-align: left;
					margin-top: 3rem;
					width: 100%;
				}
			}
		}

		.fax,
		.email,
		.facebook,
		.xing {
			margin-top: .8rem;
			display: block;
		}

		.headline {
			font-size: 1.8rem;
		}
	}