/************************************************
* CALLTOACTION
*************************************************/
    
    .callToAction {
        background-color: $brandFirst;
        padding: 6rem 0;
        text-align: center;
        font-size: 2rem;
        color: white;
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 1200px) {
        .callToAction {
            padding: 4rem 0;
        }
    }

    @media (max-width: 600px) {
        .callToAction {
            padding: 3rem 0;
        }

        .callToAction {
            font-size: 1.8rem;
        }
    }