/************************************************
* DOWNLOAD
*************************************************/

    .download {
        padding: 6rem 0;
        background-color: $brandSecond;

        .entries {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        h1 {
            margin-bottom: 2rem;
        }

        a.entry,
        div.entry {
            display: block;
            width: 32%;
            margin: 0 2% 4rem 0;
            text-decoration: none;

            &:nth-child(3n) {
                margin-right: 0;
            }

            h1,
            h2,
            h3 {
                margin: 0 0 .5rem 0;
                color: $brandFirst;

                & + p {
                    margin-top: 0;
                }
            }

            .image {
                position: relative;
                display: block;
                background-color: $brandThird;
                margin-bottom: 2rem;

                img {
                    display: block;
                    transition: all .4s ease-in-out;
                }
            }

        }

        a.entry {

            @media (min-width: 1025px) {
                &:hover {
                    text-decoration: none;

                    .image img {
                        opacity: .1;
                    }
                    .title {
                        opacity: 1; 
                    }
                }
            }

            .title {
                opacity: 0;
                position: absolute;
                top: 58%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 2rem;
                color: $brandSecond;
                transition: all .4s ease-in;
                text-align: center;

                &::before {
                    @extend %icons;
                    content: '\e806';
                    font-size: 5rem;
                    position: absolute;
                    top: -6rem;
                    left: 50%;
                    transform: translateX(-50%);
                    color: rgba($brandSecond, .4);
                }
            }
        }

    }

/************************************************
* RESPONSIVE
*************************************************/
    
    @media (max-width: 1200px) {
        .download {
            padding: 4rem 0;
        }
    }

    @media (max-width: 1024px) {
        .download {
            a.entry{
                .title {
                    opacity: 1;
                    top: auto;
                    right: 4rem;
                    left: auto;

                    &::before {
                        color: rgba(white, .9);
                        text-shadow: 1px 1px #000000;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width: 1000px) {
        .download {
            a.entry,
            div.entry {
                width: 48.5%;
                margin-right: 3%;

                &:nth-child(3n) {
                    margin-right: 3%;
                }

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .download {
            padding: 2rem 0;
        }
    }

    @media (max-width: 550px) {
        .download {
            a.entry,
            div.entry {
                width: 100%;
                margin-right: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }