/************************************************
* IMAGELIST
*************************************************/

    .imageList {
        padding: 6rem 0;
        background-color: $brandSecond;

        .wrap::after {
            display: none;
        }

        h2 {
            margin-top: 0;  
        }

        .entries {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .entry {
            width: 32%;
            margin: 0 2% 2.5rem 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &:nth-of-type(3n) {
                margin-right: 0;
            }

            .image {
                width: 45%;
            }

            figure {
                margin: 0;
                img {
                    box-shadow: 2px 2px 15px 5px rgba(10,10,10,0.2);
                    border-radius: 6px;
                }
            }

            p {
                width: 53%;
                display: inline;
                align-self: flex-end;
            }
        }
    }

/************************************************
* RESPONSIVE
*************************************************/
    
    @media (max-width: 1200px) {
        .imageList {
            padding: 4rem 0;
        }
    }

    @media (max-width: 900px) {
        .imageList {
          

            .entry {
                width: 48%;

                &:nth-of-type(2n) {
                    margin-right: 0;
                }

                &:nth-of-type(3n) {
                    margin-right: 2%;
                }

                .image {
                    width: 35%;
                }

                p {
                    width: 62%;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .imageList {
            padding: 2rem 0;

            .entry,
            .entry:nth-of-type(2n),
            .entry:nth-of-type(3n) {
                width: 100%;
                margin: 2rem 0 0 0;
            }

            .entry {
                .image {
                    width: 25%;
                }

                p {
                    width: 72%;
                }
            }
        }
    }

    @media (max-width: 400px) {
        .imageList {
            .entry {
                .image {
                    width: 35%;
                }

                p {
                    width: 62%;
                }
            }
        }
    }