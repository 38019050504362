/************************************************
* ICONS (fontello.com)
*************************************************/

@font-face {
  font-family: 'agoform-icons';
  src: url('/fileadmin/agoform/fonts/agoform-icons.eot?77128659');
  src: url('/fileadmin/agoform/fonts/agoform-icons.eot?77128659#iefix') format('embedded-opentype'),
       url('/fileadmin/agoform/fonts/agoform-icons.woff2?77128659') format('woff2'),
       url('/fileadmin/agoform/fonts/agoform-icons.woff?77128659') format('woff'),
       url('/fileadmin/agoform/fonts/agoform-icons.ttf?77128659') format('truetype'),
       url('/fileadmin/agoform/fonts/agoform-icons.svg?77128659#agoform-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icons{
    font-family: "agoform-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    @extend %icons;
}

.icon-mail:before { content: '\e800'; } /* '' */
.icon-phone:before { content: '\e801'; } /* '' */
.icon-print:before { content: '\e802'; } /* '' */
.icon-home:before { content: '\e803'; } /* '' */
.icon-search:before { content: '\e804'; } /* '' */
.icon-ok:before { content: '\e805'; } /* '' */
.icon-download:before { content: '\e806'; } /* '' */
.icon-pencil:before { content: '\e807'; } /* '' */
.icon-location:before { content: '\e808'; } /* '' */
.icon-down-open-big:before { content: '\e809'; } /* '' */
.icon-left-open-big:before { content: '\e80a'; } /* '' */
.icon-right-open-big:before { content: '\e80b'; } /* '' */
.icon-up-open-big:before { content: '\e80c'; } /* '' */
.icon-globe:before { content: '\e80d'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-export-alt:before { content: '\f14d'; } /* '' */
.icon-doc-inv:before { content: '\f15b'; } /* '' */
.icon-youtube-squared:before { content: '\f166'; } /* '' */
.icon-xing-squared:before { content: '\f169'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */