@charset "UTF-8";
/************************************************
* SETUP
*************************************************/
/* clearfix */
/* Breakpoints */
/************************************************
* Base Definitions
*************************************************/
/*****************
* Colors
******************/
/************************************************
* ICONS (fontello.com)
*************************************************/
@font-face {
  font-family: 'agoform-icons';
  src: url("/fileadmin/agoform/fonts/agoform-icons.eot?77128659");
  src: url("/fileadmin/agoform/fonts/agoform-icons.eot?77128659#iefix") format("embedded-opentype"), url("/fileadmin/agoform/fonts/agoform-icons.woff2?77128659") format("woff2"), url("/fileadmin/agoform/fonts/agoform-icons.woff?77128659") format("woff"), url("/fileadmin/agoform/fonts/agoform-icons.ttf?77128659") format("truetype"), url("/fileadmin/agoform/fonts/agoform-icons.svg?77128659#agoform-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before, .download a.entry .title::before, .article a::after, .accordion dt.open::after, .accordion dt::after, .accordion dd a::after, .formLayout0 .actions button::after, .select2-container--default .select2-selection--single .select2-selection__arrow b::after, .tel::before, .fax::before, .email::before, #navSocial a::before {
  font-family: "agoform-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none; }

.icon-mail:before {
  content: '\e800'; }

/* '' */
.icon-phone:before {
  content: '\e801'; }

/* '' */
.icon-print:before {
  content: '\e802'; }

/* '' */
.icon-home:before {
  content: '\e803'; }

/* '' */
.icon-search:before {
  content: '\e804'; }

/* '' */
.icon-ok:before {
  content: '\e805'; }

/* '' */
.icon-download:before {
  content: '\e806'; }

/* '' */
.icon-pencil:before {
  content: '\e807'; }

/* '' */
.icon-location:before {
  content: '\e808'; }

/* '' */
.icon-down-open-big:before {
  content: '\e809'; }

/* '' */
.icon-left-open-big:before {
  content: '\e80a'; }

/* '' */
.icon-right-open-big:before {
  content: '\e80b'; }

/* '' */
.icon-up-open-big:before {
  content: '\e80c'; }

/* '' */
.icon-globe:before {
  content: '\e80d'; }

/* '' */
.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-up:before {
  content: '\f106'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.icon-export-alt:before {
  content: '\f14d'; }

/* '' */
.icon-doc-inv:before {
  content: '\f15b'; }

/* '' */
.icon-youtube-squared:before {
  content: '\f166'; }

/* '' */
.icon-xing-squared:before {
  content: '\f169'; }

/* '' */
.icon-youtube-play:before {
  content: '\f16a'; }

/* '' */
.icon-right:before {
  content: '\f178'; }

/* '' */
.icon-facebook-squared:before {
  content: '\f308'; }

/* '' */
/************************************************
* Library's (optional)
*************************************************/
/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 *  Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

_ .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline {
  float: left; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left; }

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0; }

/* Caption */
.fancybox-caption {
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px 25px 44px;
  right: 0;
  text-align: center;
  z-index: 99996; }

.fancybox-caption::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAD6CAQAAADKSeXYAAAAYklEQVQoz42RwQ3AMAgDjfcfup8WoRykfBAK5mQHKSz5rbXJPis1hjiV3CIqgG0hLZPkVkA4p4x5oR1bVeDrdCLrW2Q0D5bcwY3TGMHbdw3mPRuOtaspYP1w//G1OIcW148H0DMCqI/3mMMAAAAASUVORK5CYII=);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -44px;
  z-index: -1; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  -webkit-animation: fancybox-rotate 1s linear infinite;
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes fancybox-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-caption {
    padding: 12px; }
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; } }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

/************************************************
* CONTENT
*************************************************/
/************************************************
* CONTENTSLIDER
*************************************************/
.contentSlider {
  background-color: #e9e9e9;
  padding: 6rem 0 8rem 0;
  /* contentSlider right*/ }
  .contentSlider .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .contentSlider .text {
    width: 48%; }
    .contentSlider .text h2 {
      margin-top: 0;
      margin-bottom: 0; }
    .contentSlider .text p {
      margin: 1rem 0 1.5rem 0; }
  .contentSlider .contentSliderWrapper {
    width: 48%;
    position: relative;
    padding: 0 3rem;
    display: block; }
  .contentSlider .slide figure {
    margin: 0; }
  .contentSlider .slide img {
    display: block;
    margin: 0 auto;
    width: auto; }
  .contentSlider .slide figcaption {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.8rem; }
  .contentSlider.right {
    background-color: #f6f6f6; }
    .contentSlider.right .contentSliderWrapper {
      order: 1; }

/* slider */
.owl-theme .owl-controls {
  margin: 0; }

.owl-theme .owl-nav {
  margin: 0; }
  .owl-theme .owl-nav .owl-prev,
  .owl-theme .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #2c2c2b;
    font-size: 2.7rem;
    margin: 0;
    background: transparent none; }
    .owl-theme .owl-nav .owl-prev:hover,
    .owl-theme .owl-nav .owl-next:hover {
      background: transparent none;
      color: #939390; }
    .owl-theme .owl-nav .owl-prev i::before,
    .owl-theme .owl-nav .owl-next i::before {
      width: auto;
      margin: 0; }
  .owl-theme .owl-nav .owl-prev {
    left: 0;
    padding-right: 2rem; }
  .owl-theme .owl-nav .owl-next {
    right: 0;
    padding-left: 2rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .contentSlider {
    padding: 4rem 0 7rem 0; } }

@media (max-width: 1024px) {
  .contentSlider {
    padding: 4rem 0 5rem 0; }
    .contentSlider .slide img {
      width: 85%; } }

@media (max-width: 900px) {
  .contentSlider {
    padding: 4rem 0 7rem 0; }
    .contentSlider .wrap {
      flex-direction: column-reverse; }
    .contentSlider.right .contentSliderWrapper {
      order: 0; }
    .contentSlider .contentSliderWrapper,
    .contentSlider .text {
      width: 100%; }
    .contentSlider .contentSliderWrapper {
      margin-top: 2.5rem; }
    .contentSlider .text p {
      margin-bottom: 2rem; }
    .contentSlider .slide img {
      width: 70%; } }

@media (max-width: 600px) {
  .contentSlider {
    padding: 2rem 0 6rem 0; }
    .contentSlider .slide img {
      width: 100%; } }

/************************************************
* CALLTOACTION
*************************************************/
.callToAction {
  background-color: #dd1617;
  padding: 6rem 0;
  text-align: center;
  font-size: 2rem;
  color: white; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .callToAction {
    padding: 4rem 0; } }

@media (max-width: 600px) {
  .callToAction {
    padding: 3rem 0; }
  .callToAction {
    font-size: 1.8rem; } }

/************************************************
* IMAGEFULLWIDTH
*************************************************/
.imageFullWidth {
  position: relative; }
  .imageFullWidth .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  .imageFullWidth img {
    display: block; }
  .imageFullWidth .imageText {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15%;
    transform: translateY(-50%);
    z-index: 10;
    text-align: center;
    padding: 0 5%;
    font-size: 2rem;
    color: #e9e9e9;
    display: block; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 900px) {
  .imageFullWidth .imageText {
    padding: 0 3%; } }

@media (max-width: 800px) {
  .imageFullWidth .imageText {
    bottom: 10%; } }

@media (max-width: 500px) {
  .imageFullWidth .imageText {
    bottom: 6%; } }

/************************************************
* DARKTEXTBOX
*************************************************/
.darkTextBox {
  background-color: #2c2c2b;
  padding: 6rem 0;
  text-align: center; }
  .darkTextBox .text {
    width: 60%;
    margin: 0 auto; }
  .darkTextBox h1,
  .darkTextBox h2 {
    margin-top: 0;
    color: white; }
  .darkTextBox p {
    color: white; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .darkTextBox {
    padding: 4rem 0; } }

@media (max-width: 900px) {
  .darkTextBox .text {
    width: 80%; } }

@media (max-width: 600px) {
  .darkTextBox {
    padding: 2rem 0; }
    .darkTextBox .text {
      width: 100%; } }

/************************************************
* IMAGETEXTBOX
*************************************************/
.imageTextBox {
  background-color: #f6f6f6;
  padding: 6rem 0; }
  .imageTextBox h2 {
    margin-top: 0; }
  .imageTextBox h3 {
    color: #dd1617;
    margin: 0; }
  .imageTextBox p {
    margin-top: 1.5rem; }
  .imageTextBox .content {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem; }
    .imageTextBox .content .image,
    .imageTextBox .content .text {
      width: 47%; }

.imageTextBox + .imageTextBox {
  padding-top: 0; }
  .imageTextBox + .imageTextBox .content {
    margin-top: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .imageTextBox {
    padding: 4rem 0; }
  .content .image {
    width: 40%; }
  .content .text {
    width: 56%; } }

@media (max-width: 900px) {
  .imageTextBox .content {
    display: block;
    margin-top: 5rem; }
    .imageTextBox .content .image {
      width: 80%; }
    .imageTextBox .content .text {
      width: 80%;
      margin-top: 2rem; } }

@media (max-width: 600px) {
  .imageTextBox {
    padding: 2rem 0; }
    .imageTextBox .content .image,
    .imageTextBox .content .text {
      width: 100%; } }

/************************************************
* IMAGETEXTLIST
*************************************************/
.imageTextList {
  background-color: #f6f6f6;
  padding: 6rem 0 4rem 0; }
  .imageTextList .wrap::after {
    display: none; }
  .imageTextList .wrap > h1,
  .imageTextList .wrap > h2 {
    margin: 0 0 3rem 0; }
  .imageTextList .entries {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .imageTextList .entry {
    width: 32%;
    margin: 0 2% 4rem 0;
    text-decoration: none; }
    .imageTextList .entry:nth-of-type(3n) {
      margin-right: 0; }
    .imageTextList .entry h2 {
      margin-bottom: 0; }
    .imageTextList .entry h3 {
      color: #dd1617;
      margin-bottom: .5rem; }
    .imageTextList .entry li {
      word-wrap: break-word; }
    .imageTextList .entry i {
      color: #dd1617;
      margin-right: 1rem; }
    .imageTextList .entry p {
      margin: 0; }
    .imageTextList .entry .button {
      margin-left: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .imageTextList {
    padding: 4rem 0; } }

@media (max-width: 1000px) {
  .imageTextList .entry {
    width: 48.5%;
    margin-right: 3%; }
    .imageTextList .entry:nth-of-type(3n) {
      margin-right: 3%; }
    .imageTextList .entry:nth-of-type(2n) {
      margin-right: 0; } }

@media (max-width: 600px) {
  .imageTextList {
    padding: 2rem 0; }
    .imageTextList h2 {
      margin: 0 0 2rem 0; } }

@media (max-width: 550px) {
  .imageTextList .entry {
    width: 100%;
    margin-right: 0; }
    .imageTextList .entry:last-of-type {
      margin-bottom: 0; }
    .imageTextList .entry:nth-of-type(3n) {
      margin-right: 0; } }

/************************************************
* DOWNLOAD
*************************************************/
.download {
  padding: 6rem 0;
  background-color: #e9e9e9; }
  .download .entries {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .download h1 {
    margin-bottom: 2rem; }
  .download a.entry,
  .download div.entry {
    display: block;
    width: 32%;
    margin: 0 2% 4rem 0;
    text-decoration: none; }
    .download a.entry:nth-child(3n),
    .download div.entry:nth-child(3n) {
      margin-right: 0; }
    .download a.entry h1,
    .download a.entry h2,
    .download a.entry h3,
    .download div.entry h1,
    .download div.entry h2,
    .download div.entry h3 {
      margin: 0 0 .5rem 0;
      color: #dd1617; }
      .download a.entry h1 + p,
      .download a.entry h2 + p,
      .download a.entry h3 + p,
      .download div.entry h1 + p,
      .download div.entry h2 + p,
      .download div.entry h3 + p {
        margin-top: 0; }
    .download a.entry .image,
    .download div.entry .image {
      position: relative;
      display: block;
      background-color: #2c2c2b;
      margin-bottom: 2rem; }
      .download a.entry .image img,
      .download div.entry .image img {
        display: block;
        transition: all .4s ease-in-out; }
  @media (min-width: 1025px) {
    .download a.entry:hover {
      text-decoration: none; }
      .download a.entry:hover .image img {
        opacity: .1; }
      .download a.entry:hover .title {
        opacity: 1; } }
  .download a.entry .title {
    opacity: 0;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2rem;
    color: #e9e9e9;
    transition: all .4s ease-in;
    text-align: center; }
    .download a.entry .title::before {
      content: '\e806';
      font-size: 5rem;
      position: absolute;
      top: -6rem;
      left: 50%;
      transform: translateX(-50%);
      color: rgba(233, 233, 233, 0.4); }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .download {
    padding: 4rem 0; } }

@media (max-width: 1024px) {
  .download a.entry .title {
    opacity: 1;
    top: auto;
    right: 4rem;
    left: auto; }
    .download a.entry .title::before {
      color: rgba(255, 255, 255, 0.9);
      text-shadow: 1px 1px #000000; }
    .download a.entry .title span {
      display: none; } }

@media (max-width: 1000px) {
  .download a.entry,
  .download div.entry {
    width: 48.5%;
    margin-right: 3%; }
    .download a.entry:nth-child(3n),
    .download div.entry:nth-child(3n) {
      margin-right: 3%; }
    .download a.entry:nth-child(2n),
    .download div.entry:nth-child(2n) {
      margin-right: 0; } }

@media (max-width: 600px) {
  .download {
    padding: 2rem 0; } }

@media (max-width: 550px) {
  .download a.entry,
  .download div.entry {
    width: 100%;
    margin-right: 0; }
    .download a.entry:last-child,
    .download div.entry:last-child {
      margin-bottom: 0; }
    .download a.entry:nth-of-type(3n),
    .download div.entry:nth-of-type(3n) {
      margin-right: 0; } }

/************************************************
* videoElement
*************************************************/
.videoElement {
  position: relative; }
  .videoElement .image {
    background-size: cover;
    background-position: center;
    padding-top: 25%; }
  .videoElement > span {
    background-color: rgba(0, 0, 0, 0.6);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .videoElement .content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: block;
    width: 100%;
    color: #e9e9e9;
    text-align: center;
    padding: 0 5%; }
  .videoElement .content h1, .videoElement .content h2, .videoElement .content h3 {
    color: white; }
  .videoElement .buttons {
    text-align: center; }
  .videoElement .button {
    display: inline-block; }

.fancybox-slide > video {
  max-width: 90%; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1024px) {
  .videoElement .image {
    padding-top: 28%; } }

@media (max-width: 900px) {
  .videoElement .image {
    padding-top: 32%; }
  .videoElement .content {
    padding: 0 3%; } }

@media (max-width: 700px) {
  .videoElement .image {
    padding-top: 40%; } }

@media (max-width: 650px) {
  .videoElement .image {
    padding-top: 40%; } }

@media (max-width: 550px) {
  .videoElement .image {
    padding-top: 55%; } }

@media (max-width: 450px) {
  .videoElement .image {
    padding-top: 65%; } }

/************************************************
* IMAGELIST
*************************************************/
.imageList {
  padding: 6rem 0;
  background-color: #e9e9e9; }
  .imageList .wrap::after {
    display: none; }
  .imageList h2 {
    margin-top: 0; }
  .imageList .entries {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
  .imageList .entry {
    width: 32%;
    margin: 0 2% 2.5rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .imageList .entry:nth-of-type(3n) {
      margin-right: 0; }
    .imageList .entry .image {
      width: 45%; }
    .imageList .entry figure {
      margin: 0; }
      .imageList .entry figure img {
        box-shadow: 2px 2px 15px 5px rgba(10, 10, 10, 0.2);
        border-radius: 6px; }
    .imageList .entry p {
      width: 53%;
      display: inline;
      align-self: flex-end; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .imageList {
    padding: 4rem 0; } }

@media (max-width: 900px) {
  .imageList .entry {
    width: 48%; }
    .imageList .entry:nth-of-type(2n) {
      margin-right: 0; }
    .imageList .entry:nth-of-type(3n) {
      margin-right: 2%; }
    .imageList .entry .image {
      width: 35%; }
    .imageList .entry p {
      width: 62%; } }

@media (max-width: 600px) {
  .imageList {
    padding: 2rem 0; }
    .imageList .entry,
    .imageList .entry:nth-of-type(2n),
    .imageList .entry:nth-of-type(3n) {
      width: 100%;
      margin: 2rem 0 0 0; }
    .imageList .entry .image {
      width: 25%; }
    .imageList .entry p {
      width: 72%; } }

@media (max-width: 400px) {
  .imageList .entry .image {
    width: 35%; }
  .imageList .entry p {
    width: 62%; } }

/************************************************
* ARTICLES
*************************************************/
.articles {
  padding: 6rem 0;
  background-color: #f6f6f6; }
  .articles h2 {
    margin-top: 0; }

.article {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 4rem; }
  .article:first-of-type {
    margin-top: 0; }
  .article .image {
    width: 18%;
    margin-right: 2rem; }
  .article .text {
    width: 65%; }
  .article h3 {
    margin-top: 0;
    color: #dd1617; }
  .article a::after {
    content: '\f105'; }
  .article a:hover::after {
    text-decoration: none; }

ul.f3-widget-paginator li::before {
  content: ''; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1300px) {
  .article .image {
    width: 20%; } }

@media (max-width: 1200px) {
  .articles {
    padding: 4rem 0; } }

@media (max-width: 1050px) {
  .article .image {
    width: 23%; } }

@media (max-width: 800px) {
  .article .image {
    width: 27%; } }

@media (max-width: 700px) {
  .articles {
    display: block; }
  .article .image,
  .article .text {
    width: 100%; }
  .article .text {
    margin-top: 1.5rem; } }

@media (max-width: 600px) {
  .articles {
    padding: 2rem 0; } }

/************************************************
* ACCORDION
*************************************************/
.accordion {
  padding: 6rem 0;
  background-color: #e9e9e9; }

.accordion h2 {
  margin-top: 0; }

.accordion dt {
  background-color: #2c2c2b;
  color: #e9e9e9;
  font-size: 2rem;
  padding: 1.5rem 9rem 1.5rem 3rem;
  position: relative;
  margin-top: 2rem;
  cursor: pointer; }
  .accordion dt.open {
    display: block; }
    .accordion dt.open::after {
      content: '\f106';
      position: absolute;
      right: 1.5%;
      top: 35%; }

.accordion dt::before {
  content: '';
  width: 2px;
  background-color: #dd1617;
  position: absolute;
  top: 1.3rem;
  bottom: 1.3rem;
  right: 5.5rem; }

.accordion dt::after {
  content: '\f107';
  position: absolute;
  top: 35%;
  right: 0;
  width: 5rem; }

.accordion dd {
  background-color: white;
  margin: 0;
  padding: 2.5rem 3rem 3rem 3rem;
  font-size: 1.8rem; }
  .accordion dd.closed {
    display: none; }

.accordion dd a {
  display: block;
  margin-top: 2rem; }

.accordion dd a::after {
  content: '\f105'; }

.accordion dd a:hover::after {
  text-decoration: none; }

.accordion dd h3 {
  color: #dd1617;
  margin: 2rem 0 .5rem 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1200px) {
  .accordion {
    padding: 4rem 0; } }

@media (max-width: 900px) {
  .accordion dt::after {
    top: 25%; }
  .accordion dt {
    padding: 1rem 7rem 1rem 1.5rem; }
  .accordion dd {
    padding: 1.5rem; } }

@media (max-width: 750px) {
  .accordion dt::after {
    top: 30%; }
  .accordion dt {
    font-size: 1.8rem; } }

@media (max-width: 600px) {
  .accordion {
    padding: 2rem 0; } }

/************************************************
* formLayout0
*************************************************/
.formLayout0 {
  padding: 0 0 6rem 0;
  background-color: #f6f6f6;
  /* static text */
  /* immer um ein form element (label + div) */
  /* immer um ein static text element */
  /* Grid Row */
  /* Label */
  /* Fieldset */
  /* Input & Textarea */
  /* file */
  /* Submit */ }
  .formLayout0 h1,
  .formLayout0 h2 {
    margin-top: 0; }
  .formLayout0 h2 + div {
    margin-bottom: 4rem; }
  .formLayout0 .form-group {
    margin-bottom: 3rem;
    display: flex;
    justify-content: flex-start; }
    .formLayout0 .form-group > label {
      width: 30%; }
    .formLayout0 .form-group > div,
    .formLayout0 .form-group > p {
      width: 50%; }
      .formLayout0 .form-group > div.checkbox,
      .formLayout0 .form-group > p.checkbox {
        margin-left: 30%; }
    .formLayout0 .form-group .control-label + .checkbox {
      margin-left: 0; }
  .formLayout0 .row .clearfix {
    padding-left: 30%;
    padding-right: 20%;
    margin-top: -2rem;
    margin-bottom: 3rem; }
    .formLayout0 .row .clearfix p {
      margin-top: 0; }
  .formLayout0 .row {
    width: 100%;
    margin-bottom: 2rem; }
    .formLayout0 .row + .row h2 {
      margin-top: 3rem; }
  .formLayout0 label {
    width: 100%;
    color: #444343;
    font-size: 1.8rem;
    display: block;
    padding: 1.3rem;
    padding-left: 0; }
  .formLayout0 fieldset {
    margin: 0 0 2rem 0;
    padding: 0;
    border: 0; }
    .formLayout0 fieldset legend {
      font-size: 2rem;
      font-weight: 600;
      color: #dd1617;
      margin-bottom: 2rem;
      padding: 0; }
    .formLayout0 fieldset > .form-group,
    .formLayout0 fieldset .clearfix {
      margin-bottom: 1rem; }
  .formLayout0 .input {
    width: 100%; }
    .formLayout0 .input input[type="text"],
    .formLayout0 .input input[type="email"],
    .formLayout0 .input textarea {
      border: none;
      padding: 1.3rem;
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      font-size: 1.8rem;
      color: #444343;
      background-color: white;
      border-bottom: 2px solid white;
      outline: none;
      position: relative; }
      .formLayout0 .input input[type="text"]:focus,
      .formLayout0 .input input[type="email"]:focus,
      .formLayout0 .input textarea:focus {
        border-bottom: 2px solid #dd1617; }
    .formLayout0 .input input[type="checkbox"] {
      margin-right: 1rem; }
    .formLayout0 .input textarea {
      height: 20rem; }
  .formLayout0 .fileWrapper {
    position: relative; }
    .formLayout0 .fileWrapper label {
      display: block;
      height: 100%;
      font-size: 1.8rem;
      padding: 1.3rem 1.3rem 1.3rem 0;
      position: absolute;
      width: 100%;
      padding-right: 71%;
      z-index: 20; }
      .formLayout0 .fileWrapper label.selected + .button::after {
        content: '\e805'; }
    .formLayout0 .fileWrapper .button {
      margin: 0 0 0 30%;
      width: auto;
      background-color: white;
      color: #444343;
      border-radius: 0; }
      .formLayout0 .fileWrapper .button::after {
        content: '\f15b';
        color: #444343; }
    html:lang(de) .formLayout0 .fileWrapper .button span::before {
      content: 'Datei auswählen'; }
    html:lang(de) .formLayout0 .fileWrapper .button span.hasFile::before {
      content: none; }
    html:lang(en) .formLayout0 .fileWrapper .button span::before {
      content: 'Select File'; }
    html:lang(en) .formLayout0 .fileWrapper .button span.hasFile::before {
      content: none; }
    .formLayout0 .fileWrapper .input {
      width: 0; }
    .formLayout0 .fileWrapper input {
      opacity: 0;
      visibility: hidden; }
  .formLayout0 .actions {
    padding-left: 30%;
    width: 100%;
    text-align: left; }
    .formLayout0 .actions button {
      display: inline-block;
      color: white;
      font-size: 1.6rem;
      background-color: #2c2c2b;
      padding: 1.5rem 7rem 1.5rem 2rem;
      border-radius: 1rem;
      position: relative;
      transition: all .2s ease-in;
      cursor: pointer; }
      @media (min-width: 1025px) {
        .formLayout0 .actions button:hover {
          background-color: rgba(44, 44, 43, 0.8);
          text-decoration: none; } }
      .formLayout0 .actions button::before {
        content: '';
        width: 2px;
        height: 60%;
        background-color: #dd1617;
        position: absolute;
        right: 5rem;
        top: 50%;
        transform: translateY(-50%); }
      .formLayout0 .actions button::after {
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        color: white;
        content: '\f178';
        transition: color .2s ease-in; }

/************************************************
 * Select 2 Stylings überschreiben
 ************************************************/
.select2-container--default {
  font-size: 1.6rem;
  max-width: 100%;
  /* Hintergrund für aktive Auswahl*/
  /* Hintergrund für gehoverte Auswahl*/ }
  .select2-container--default .select2-selection--single {
    background-color: white;
    font-size: 1.8rem;
    border-radius: 0;
    border: none;
    padding: 1.2rem 1.3rem;
    padding-right: 8rem;
    height: auto;
    outline: none; }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: auto;
      bottom: 0;
      width: 5rem;
      top: 0;
      right: 0;
      border: none;
      background-color: white;
      border-left: 0; }
      .select2-container--default .select2-selection--single .select2-selection__arrow::before {
        display: block;
        content: '';
        border-left: 2px solid #444343;
        position: absolute;
        top: 1rem;
        bottom: 1rem; }
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border: none; }
        .select2-container--default .select2-selection--single .select2-selection__arrow b::after {
          content: '\f107';
          position: absolute;
          top: -.8rem;
          left: -.8rem;
          font-size: 2.5rem;
          color: #dd1617; }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #444343;
      font-size: 1.8rem;
      padding: 0; }
      .select2-container--default .select2-selection--single .select2-selection__rendered li:before {
        content: ''; }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border: none; }
    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b::after {
      content: '\f106'; }
  .select2-container--default .select2-results__option {
    padding: 1rem;
    color: #444343; }
  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: white; }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #d3d3d3;
    color: #444343; }
  .select2-container--default .select2-selection--multiple {
    font-size: 1.8rem;
    border-radius: 0;
    padding: .8rem .7rem .7rem .7rem;
    padding-right: 4rem;
    height: auto;
    line-height: 1.8rem; }
    .select2-container--default .select2-selection--multiple li {
      line-height: 1.5;
      font-size: 1.8rem; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
      margin-top: .2rem; }
    .select2-container--default .select2-selection--multiple .select2-search--inline::before {
      content: ''; }
      .select2-container--default .select2-selection--multiple .select2-search--inline::before input {
        font-size: 1.8rem; }

/* Hintergrund generell für die Optionen */
.select2-dropdown {
  background-color: white;
  border: none;
  border-radius: 0; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1024px) {
  .formLayout0 .form-group > div {
    width: 70%; }
  .formLayout0 .row .clearfix {
    padding-right: 0; } }

@media (max-width: 700px) {
  .formLayout0 .form-group {
    display: block; }
    .formLayout0 .form-group label,
    .formLayout0 .form-group div {
      width: 100%; }
      .formLayout0 .form-group label.checkbox,
      .formLayout0 .form-group div.checkbox {
        margin-left: 0; }
  .formLayout0 .row .clearfix {
    padding-left: 0; }
  .formLayout0 .actions {
    padding-left: 0; }
  .formLayout0 .fileWrapper label {
    padding-right: 0; }
  .formLayout0 .fileWrapper .button {
    margin: 6rem 0 0 0; }
  .formLayout0 .fileWrapper .input {
    margin: 0;
    padding: 0;
    height: 0; }
  .formLayout0 .fileWrapper input {
    height: 0;
    padding: 0; } }

/************************************************
* mapElement
*************************************************/
.mapElement .maps-container {
  position: relative;
  padding-bottom: 30%;
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto; }
  .mapElement .maps-container .map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .mapElement .maps-container .marker {
    display: none; }

/************************************************
* RESPONSIVE
*************************************************/
/************************************************
* FOOTER
*************************************************/
/************************************************
* General CONTENT Stylings
*************************************************/
footer {
  background-color: #2c2c2b;
  color: #e9e9e9;
  padding: 3rem 0; }
  footer .wrap {
    display: flex;
    justify-content: space-between; }
    footer .wrap::after {
      display: none; }
  footer .left {
    width: 60%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    footer .left .col {
      width: 32%; }
  footer .right {
    width: 25%;
    display: flex;
    justify-content: flex-end; }
    footer .right .col {
      width: 48%;
      font-size: 1.3rem;
      line-height: 1.7;
      align-self: flex-end; }
      footer .right .col:last-of-type {
        text-align: right; }
  footer .headline {
    font-size: 1.5rem;
    margin-bottom: 2rem; }
  html:lang(de) footer .social::before {
    content: 'Besuchen Sie uns auf'; }
  html:lang(en) footer .social::before {
    content: 'Visit us at'; }
  html:lang(de) footer .contact::before {
    content: 'Kontakt'; }
  html:lang(en) footer .contact::before {
    content: 'Contact'; }
  html:lang(de) footer .country::before {
    content: 'Deutschland'; }
  html:lang(en) footer .country::before {
    content: 'Germany'; }

address {
  font-size: 1.3rem;
  line-height: 1.7;
  font-style: normal; }

.tel,
.email,
.fax,
a[href^="tel"] {
  text-decoration: none;
  color: #e9e9e9;
  display: block; }

.tel:hover,
.fax:hover,
.email:hover,
a[href^="tel"]:hover {
  color: #b6b6b6; }

.tel:hover:before,
.fax:hover:before,
.email:hover:before,
a[href^="tel"] {
  text-decoration: none; }

.tel::before {
  content: '\e801';
  color: #dd1617;
  margin-right: 1.5rem; }

.fax::before {
  content: '\e802';
  color: #dd1617;
  margin-right: 1.5rem; }

.email::before {
  content: '\e800';
  color: #dd1617;
  margin-right: 1.5rem; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1024px) {
  footer .left {
    width: 65%; }
  footer .right {
    width: 30%; }
    footer .right .col {
      font-size: 1.5rem; }
  footer .headline {
    font-size: 1.7rem; }
  address {
    font-size: 1.5rem; }
  .tel:hover,
  .email:hover {
    color: #e9e9e9; } }

@media (max-width: 900px) {
  footer .wrap {
    display: block; }
  footer .left {
    justify-content: space-between;
    width: 100%; }
  footer .right {
    width: 100%;
    justify-content: space-between;
    margin-top: 4rem; }
    footer .right .col:first-of-type {
      width: 85%; }
    footer .right .col:last-of-type {
      width: 15%; } }

@media (max-width: 600px) {
  footer .left .col {
    width: 48%;
    margin-bottom: 3rem; }
    footer .left .col:last-of-type {
      margin-bottom: 0; }
  footer .headline {
    margin-bottom: 1rem; } }

@media (max-width: 500px) {
  footer .left .col {
    width: 100%; }
  footer .right {
    display: block; }
    footer .right:first-of-type {
      width: 100%; }
    footer .right .col:last-of-type {
      text-align: left;
      margin-top: 3rem;
      width: 100%; }
  .fax,
  .email,
  .facebook,
  .xing {
    margin-top: .8rem;
    display: block; }
  .headline {
    font-size: 1.8rem; } }

/************************************************
* navSocial
*************************************************/
#navSocial {
  font-size: 1.3rem;
  line-height: 1.7; }
  #navSocial ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  #navSocial a {
    color: #e9e9e9;
    text-decoration: none; }
    @media (min-width: 1025px) {
      #navSocial a:hover {
        color: #b6b6b6; } }
    #navSocial a::before {
      color: #dd1617;
      margin-right: 1.5rem; }
    #navSocial a[href*="youtube.com"]::before {
      content: '\f16a'; }
    #navSocial a[href*="facebook.com"]::before {
      content: '\f308'; }
    #navSocial a[href*="xing.com"]::before {
      content: '\f169'; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1024px) {
  #navSocial a {
    font-size: 1.5rem; }
  #navSocial ul li a:hover {
    color: #e9e9e9; } }

@media (max-width: 900px) {
  #navSocial ul li {
    display: inline-block;
    margin-right: 2rem; } }

@media (max-width: 500px) {
  #navSocial a {
    font-size: 1.6rem; }
  #navSocial ul li {
    display: block;
    margin-top: 1rem; } }

/************************************************
* navFooter
*************************************************/
#navFooter ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

#navFooter a {
  color: #e9e9e9;
  text-decoration: none; }
  #navFooter a:hover {
    color: #b6b6b6; }

/************************************************
* RESPONSIVE
*************************************************/
@media (max-width: 1024px) {
  #navFooter ul li a:hover {
    color: #e9e9e9; } }

@media (max-width: 900px) {
  #navFooter ul li {
    display: inline-block;
    margin-right: 2rem; } }

@media (max-width: 500px) {
  #navFooter ul li {
    display: block;
    margin-top: 1rem; } }
