/************************************************
* ACCORDION
*************************************************/
	
	.accordion {
		padding: 6rem 0;
		background-color: $brandSecond;
	}
	
	.accordion h2 {
		margin-top: 0;
	}

	.accordion dt {
		background-color: $brandThird;
		color: $brandSecond;
		font-size: 2rem;
		padding: 1.5rem 9rem 1.5rem 3rem;
		position: relative;
		margin-top: 2rem;
		cursor: pointer;

		&.open {
			display: block;

			&::after {
				@extend %icons;
				content: '\f106';
				position: absolute;
				right: 1.5%;
				top: 35%;
			}
		}
	}

	.accordion dt::before {
		content: '';
		width: 2px;
		background-color: $brandFirst;
		position: absolute;
		top: 1.3rem;
		bottom: 1.3rem;
		right: 5.5rem;
	}

	.accordion dt::after {
		@extend %icons;
		content: '\f107';
		position: absolute;
		top: 35%;
		right: 0;
		width: 5rem;
	}

	.accordion dd {
		background-color: white;
		margin: 0;
		padding: 2.5rem 3rem 3rem 3rem;
		font-size: 1.8rem;
		
		&.closed {
			display: none;
		}
	}

	.accordion dd a {
		display: block;
		margin-top: 2rem;
	}

	.accordion dd a::after {
		@extend %icons;
		content: '\f105';
	}

	.accordion dd a:hover::after {
		text-decoration: none;
	}

	.accordion dd h3 {
		color: $brandFirst;
		margin: 2rem 0 .5rem 0;
	}


/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1200px) {
		.accordion {
			padding: 4rem 0;
		}
	}

	@media (max-width: 900px) {

		.accordion dt::after {
			top: 25%;
		}

		.accordion dt {
			padding: 1rem 7rem 1rem 1.5rem;
		}

		.accordion dd {
			padding: 1.5rem;
		}
	}

	@media (max-width: 750px) {

		.accordion dt::after {
			top: 30%;
		}

		.accordion dt {
			font-size: 1.8rem;
		}
	}

	@media (max-width: 600px) {
		.accordion {
			padding: 2rem 0;
		}
	}