/************************************************
* videoElement
*************************************************/

	.videoElement {
		position: relative;

		.image {
			background-size: cover;
			background-position: center;
			padding-top: 25%;
		}

		> span {
			background-color: rgba(0, 0, 0, .6);
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.content {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			display: block;
			width: 100%;
			color: $brandSecond;
			text-align: center;
			padding: 0 5%;
		}

		.content {
			h1,h2,h3 {
				color: white;
			}
		}

		.buttons {
			text-align: center;
		}
		
		.button {
			display: inline-block;
		}

	}

	.fancybox-slide > video {

		max-width: 90%;
	}

/************************************************
* RESPONSIVE
*************************************************/

	@media (max-width: 1024px) {
		.videoElement .image {
			padding-top: 28%;
		}
	}

	@media (max-width: 900px) {
		.videoElement{
			.image {
				padding-top: 32%;
			}

			.content {
				padding: 0 3%;
			}
		}
	}

	@media (max-width: 700px) {
		.videoElement .image {
			padding-top: 40%;
		}
	}

	@media (max-width: 650px) {
		.videoElement .image {
			padding-top: 40%;
		}
	}

	@media (max-width: 550px) {
		.videoElement .image {
			padding-top: 55%;
		}
	}

	@media (max-width: 450px) {
		.videoElement .image {
			padding-top: 65%;
		}
	}