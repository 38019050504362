/************************************************
* DARKTEXTBOX
*************************************************/
    
    .darkTextBox {
        background-color: $brandThird;
        padding: 6rem 0;
        text-align: center;

        .text {
            width: 60%;
            margin: 0 auto;
        }

        h1,
        h2 {
            margin-top: 0;
            color: white;
        }

        p {
            color: white;
        }
    }


/************************************************
* RESPONSIVE
*************************************************/

    @media (max-width: 1200px) {
        .darkTextBox {
            padding: 4rem 0;
        }
    }

    @media (max-width: 900px) {
        .darkTextBox .text {
            width: 80%;
        }
    }

    @media (max-width: 600px) {
        .darkTextBox {
            padding: 2rem 0;
            .text {
                width: 100%;
            }
        }
    }