/************************************************
* navSocial
*************************************************/

#navSocial{
    font-size: 1.3rem;
    line-height: 1.7;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    
    a {
        color: $brandSecond;
        text-decoration: none;
        @media (min-width: 1025px) {
            &:hover {
                color: darken($brandSecond, 20%);
            }
        }
        &::before {
            @extend %icons;
            color: $brandFirst;
            margin-right: 1.5rem;
        }

        &[href*="youtube.com"]::before {
            content: '\f16a';
        }

        &[href*="facebook.com"]::before {
            content: '\f308';
        }

        &[href*="xing.com"]::before {
            content: '\f169';
        }
    }
}

/************************************************
* RESPONSIVE
*************************************************/

@media (max-width: 1024px) {
    #navSocial {
        a {
            font-size: 1.5rem;
        }

        ul li a:hover {
            color: $brandSecond;
        }
    }
}

@media (max-width: 900px) {
    #navSocial ul li {
        display: inline-block;
        margin-right: 2rem;
    }
}

@media (max-width: 500px) {
    #navSocial {
        a {
            font-size: 1.6rem;
        }

        ul li {
            display: block;
            margin-top: 1rem;
        }
    }
}